import { getRemPropertyPixelSize } from './rem';

/**
 * Returns the text width for a specific font.
 *
 * @param text The text to measure.
 * @param font The name of the font, defined with the "define-font" mixin, to use when measuring.
 * @returns The width of the text rendered with the font.
 */
export function measureTextWidth(text: string, font: string): number {
  const fontWeight = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(`--${font}-font-weight`);
  const fontSize = getRemPropertyPixelSize(
    document.documentElement,
    `--${font}-font-size`
  );
  const fontFamily = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--font-family');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get 2d context from canvas');
  }
  ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
  return ctx.measureText(text).width;
}
