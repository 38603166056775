import { Theme } from '../models/theme';
import { useEffect, useState } from 'react';
import SunCalc from 'suncalc';

const DEFAULT_TIME_BETWEEN_SUN_BASED_THEME_UPDATES = 10 * 60 * 1000;

export default function useTheme(theme?: Theme) {
  const [activeTheme, setActiveTheme] = useState<Theme>(theme ?? 'dark');

  function setTheme(theme: Theme) {
    document.documentElement.classList.remove('theme--light');
    document.documentElement.classList.remove('theme--dark');
    document.documentElement.classList.add(`theme--${theme}`);

    setActiveTheme(theme);
  }

  useEffect(() => {
    if (theme !== undefined) {
      setTheme(theme);
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const themeQueryParam = queryParams.get('theme');

    let sunBasedThemeTimerId: number;

    if (themeQueryParam === 'light') {
      setTheme('light');
    } else if (themeQueryParam === 'sun-based') {
      updateSunBasedTheme();
    } else {
      setTheme('dark');
    }

    function getTimeToNextUpdate(
      now: Date,
      times: SunCalc.GetTimesResult
    ): number {
      const timeUntilSunrise = times.sunrise.getTime() - now.getTime();
      if (
        timeUntilSunrise >= 0 &&
        timeUntilSunrise < DEFAULT_TIME_BETWEEN_SUN_BASED_THEME_UPDATES
      ) {
        return timeUntilSunrise;
      }

      const timeUntilSunset = times.sunset.getTime() - now.getTime();
      if (
        timeUntilSunset >= 0 &&
        timeUntilSunset < DEFAULT_TIME_BETWEEN_SUN_BASED_THEME_UPDATES
      ) {
        return timeUntilSunset;
      }

      return DEFAULT_TIME_BETWEEN_SUN_BASED_THEME_UPDATES;
    }

    function updateSunBasedTheme() {
      const now = new Date();
      const times = SunCalc.getTimes(now, 57.708467, 11.973423);

      if (now >= times.sunrise && now < times.sunset) {
        setTheme('light');
      } else {
        setTheme('dark');
      }

      const timeToNextUpdate = getTimeToNextUpdate(now, times);

      sunBasedThemeTimerId = window.setTimeout(
        updateSunBasedTheme,
        timeToNextUpdate
      );
    }

    return () => {
      window.clearTimeout(sunBasedThemeTimerId);
    };
  }, [theme]);

  return activeTheme;
}
