import { useEffect, useState } from 'react';
import { formatDepartureTimeCountdown } from '../utils/formatDepartureTime';
import { Departure } from '../models/departure';
import { DepartureTimeFormat } from '@shared/models/departureTimeFormat';
import { dateToClockFormat } from '@shared/utils/time';

export function useDepartureDisplayTime(
  departure: Departure | undefined,
  format: DepartureTimeFormat
) {
  const [displayTime, setDisplayTime] = useState<number | string | undefined>();

  useEffect(() => {
    function getDisplayTime(
      departure: Departure | undefined
    ): number | string | undefined {
      if (!departure) {
        return undefined;
      }

      if (departure.isCancelled) {
        return 'Inställd';
      }

      if (format === 'time') {
        return dateToClockFormat(departure.estimatedOtherwisePlannedTime);
      }

      return formatDepartureTimeCountdown(
        departure.estimatedOtherwisePlannedTime
      );
    }

    setDisplayTime(getDisplayTime(departure));

    const intervalId = setInterval(() => {
      setDisplayTime(getDisplayTime(departure));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [departure, format]);

  return displayTime;
}
