import classNames from 'classnames';
import { dateToClockFormat } from '@shared/utils/time';
import { Departure } from '../models/departure';
import styles from './DepartureTablePlannedTimeCell.module.scss';
import { getHasChangedPlannedClockTime } from '../utils/departure';

interface DepartureTablePlannedTimeCellProps {
  departure: Departure;
  headerWidth?: number;
}

export default function DepartureTablePlannedTimeCell({
  departure,
  headerWidth,
}: DepartureTablePlannedTimeCellProps) {
  const plannedTimeClock = dateToClockFormat(departure.plannedTime);

  const hasChangedPlannedClockTime = getHasChangedPlannedClockTime(departure);

  return (
    <td style={{ minWidth: headerWidth }}>
      <span
        className={classNames(
          styles['planned-time'],
          hasChangedPlannedClockTime && styles['planned-time--changed']
        )}
      >
        {plannedTimeClock}
      </span>
    </td>
  );
}
