import { TransportMode } from '../models/transportMode';
import styles from './DepartureTableTransportModeCell.module.scss';
import ImageIcon from '@shared/components/ImageIcon';

interface DepartureTableTransportModeCellProps {
  transportMode: TransportMode | undefined;
}

export default function DepartureTableTransportModeCell({
  transportMode,
}: DepartureTableTransportModeCellProps) {
  function getTransportModeText(
    transportMode: TransportMode | undefined
  ): string {
    switch (transportMode) {
      case 'tram':
        return 'Spårvagn';

      case 'bus':
        return 'Buss';

      case 'ferry':
        return 'Färja';

      case 'train':
        return 'Tåg';

      default:
        return '';
    }
  }

  return (
    <td className={styles['transport-mode']}>
      {transportMode && (
        <ImageIcon
          className={styles.icon}
          type={`transport-mode-${transportMode}`}
          alt={getTransportModeText(transportMode)}
        ></ImageIcon>
      )}
    </td>
  );
}
