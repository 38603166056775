import classNames from 'classnames';
import { Departure } from '../models/departure';
import ImageIcon, { Icon } from '@shared/components/ImageIcon';
import { useDepartureDisplayTime } from '../hooks/useDepartureDisplayTime';
import styles from './DepartureTableDepartureCell.module.scss';
import { DepartureTimeFormat } from '@shared/models/departureTimeFormat';
import { getHasChangedPlannedClockTime } from '../utils/departure';

interface DepartureTableDepartureCellProps {
  departure: Departure | undefined;
  compact: boolean;
  format: DepartureTimeFormat;
  hasDepartureWithTrafficSituation: boolean;
  headerWidth?: number;
}

export default function DepartureTableDepartureCell({
  departure,
  compact,
  format,
  hasDepartureWithTrafficSituation,
  headerWidth,
}: DepartureTableDepartureCellProps) {
  const departureDisplayTime = useDepartureDisplayTime(departure, format);

  const trafficSituationIconType: Icon | undefined =
    departure?.trafficSituationSeverity
      ? `traffic-situation-${departure.trafficSituationSeverity}`
      : undefined;

  const displayInformation =
    format === 'countdown' ||
    (departure && getHasChangedPlannedClockTime(departure));

  const renderWheelchairAccessible =
    format === 'countdown' &&
    !departure?.isCancelled &&
    departure?.isWheelchairAccessible;

  return (
    <td
      className={classNames(
        styles.departure,
        departure?.isCancelled && styles['departure--cancelled']
      )}
      style={{ minWidth: headerWidth }}
    >
      {displayInformation && (
        <div
          className={classNames(
            styles['departure-information'],
            renderWheelchairAccessible &&
              styles['departure-information--wheelchair-accessible'],
            hasDepartureWithTrafficSituation &&
              styles[
                'departure-information--departure-with-traffic-situation-exist'
              ],
            compact && styles['departure-information--compact']
          )}
        >
          {trafficSituationIconType && (
            <ImageIcon
              type={trafficSituationIconType}
              alt="Avgångstiden har ett störningsmeddelande"
              className={styles['connected-traffic-situation']}
            ></ImageIcon>
          )}
          {departureDisplayTime && (
            <span
              className={classNames(
                styles['departure-information__text'],
                format === 'time' &&
                  !departure?.isCancelled &&
                  styles['departure-information__text--has-changed-clock-time']
              )}
            >
              {departureDisplayTime}
            </span>
          )}
          {departureDisplayTime && typeof departureDisplayTime === 'number' && (
            <span className="sr-only">minuter</span>
          )}
          {renderWheelchairAccessible && (
            <ImageIcon
              type="wheelchair-accessible"
              alt="Resan är anpassad för rullstol"
              className={styles['wheelchair-accessible']}
            ></ImageIcon>
          )}
        </div>
      )}
    </td>
  );
}
