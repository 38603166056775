export function getRemPropertyPixelSize(
  element: HTMLElement,
  propertyName: string
): number {
  const rootFontSize = parseFloat(
    getComputedStyle(document.documentElement).getPropertyValue('font-size')
  );
  const fontSizeRem = parseFloat(
    window.getComputedStyle(element).getPropertyValue(propertyName)
  );
  return fontSizeRem * rootFontSize;
}
