import { Departure } from '../models/departure';
import { dateToClockFormat } from '@shared/utils/time';

export function getHasChangedPlannedClockTime(departure: Departure): boolean {
  const plannedTimeClock = dateToClockFormat(departure.plannedTime);
  const estimatedOtherwisePlannedTimeClock = dateToClockFormat(
    departure.estimatedOtherwisePlannedTime
  );

  return (
    departure.isCancelled ||
    plannedTimeClock !== estimatedOtherwisePlannedTimeClock
  );
}
