import { ChangeEvent, useCallback, useEffect, useId, useState } from 'react';
import { StopArea } from '@shared/models/stopArea';
import { searchStopAreas } from '@shared/utils/stopAreas';
import { getDisplayName } from '@shared/utils/stopArea';

interface StopAreaConfiguratorProps {
  autoFocus: boolean;
  onChange: (stopArea: StopArea) => void;
}

export default function StopAreaSearch({
  autoFocus,
  onChange,
}: StopAreaConfiguratorProps) {
  const id = useId();
  const [stopAreaSearch, setStopAreaSearch] = useState('');
  const [stopAreaOptions, setStopAreaOptions] = useState<StopArea[]>([]);

  const onStopAreaSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setStopAreaSearch(event.target.value);
    },
    []
  );

  useEffect(() => {
    const abortController = new AbortController();

    async function updateStopAreaOptions() {
      if (stopAreaSearch.trim() === '') {
        setStopAreaOptions([]);
        return;
      }

      try {
        const newStopAreaOptions = await searchStopAreas(
          stopAreaSearch,
          abortController.signal
        );
        setStopAreaOptions(newStopAreaOptions);

        const selectedOption = newStopAreaOptions.find(
          (stopAreaOption) => getDisplayName(stopAreaOption) === stopAreaSearch
        );

        if (!selectedOption) {
          return;
        }

        setStopAreaSearch('');
        onChange(selectedOption);
      } catch (error) {
        if (error instanceof DOMException && error.name === 'AbortError') {
          // Ignore automatically aborted requests
        } else {
          throw error;
        }
      }
    }

    void updateStopAreaOptions();

    return () => {
      abortController.abort();
    };
  }, [onChange, stopAreaSearch]);

  return (
    <div>
      <datalist id={id}>
        {stopAreaOptions.map((stopArea) => (
          <option key={stopArea.gid} value={getDisplayName(stopArea)}></option>
        ))}
      </datalist>

      <input
        type="text"
        list={id}
        placeholder="Sök hållplats"
        value={stopAreaSearch}
        onChange={onStopAreaSearchChange}
        autoFocus={autoFocus}
      />
    </div>
  );
}
