export const MAX_NUMBER_OF_BOARDS = 10; // Should be in sync with MaxNumberOfBoards on server
export const SECONDS_OF_ERROR_BEFORE_SHOWING_ERROR_MESSAGE = 60;
export const SECONDS_OF_MAXIMUM_EXPECTED_LATENCY = 10;
export const SECONDS_BETWEEN_ALIVE_MESSAGES = 60; // Needs to be synced with SecondsBetweenAliveMessages for server
export const SECONDS_BETWEEN_EVENT_SOURCE_RECONNECTION_ATTEMPTS = 60;
export const SECONDS_OF_MAX_EXPECTED_SERVER_STARTUP_TIME = 30;
export const SECONDS_BETWEEN_DEPARTURES_PAGE_CHANGE = 10;
export const HORIZONTAL_BOARD_MINIMUM_ASPECT_RATIO = 16 / 10;

export const MINIMUM_TIME_IN_SECONDS_TO_READ_TEXT = 5;
export const CHARACTERS_READ_PER_SECOND = 20;
export const SCROLL_TIME_IN_SECONDS = 1;
export const TRAFFIC_SITUATION_MINIMUM_NUMBER_OF_CHARACTERS = 50;
export const PAGINATION_TIME_IN_SECONDS = 0.5; // Needs to be in sync with --pagination-duration

// Default values should be synced with backend defaults
export const DEPARTURE_SORTING_DEFAULT = 'alphabetical';
export const DEPARTURE_PAGING_DEFAULT = true;
export const DEPARTURE_TIME_FORMAT_DEFAULT = 'countdown';
export const DEPARTURES_TIME_SPAN_DEFAULT = 'hour';
export const SHOW_PLATFORMS_IN_HEADER_DEFAULT = true;
