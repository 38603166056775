import { RefObject, useEffect } from 'react';
import { setMaxFontSize } from '../utils/maxFontSize';

interface ScaledFontSizeOptions {
  /** Controls if line breaks between words are allowed or not. Default is false. */
  singleLine?: boolean;

  /** Controls if the scaling should happen at all, for conditional cases */
  enabled?: boolean;

  /** Reserved space for other elements */
  reservedSpace?: number;
}

/**
 * Handles automatic text scale-to-fit for an element.
 *
 * @param elementReference A reference to the element.
 */
export function useScaledFontSize(
  elementReference: RefObject<HTMLElement>,
  { singleLine, enabled, reservedSpace }: ScaledFontSizeOptions = {}
): void {
  useEffect(() => {
    if (enabled === false || elementReference.current === null) {
      return;
    }

    trySetMaxFontSize();

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        trySetMaxFontSize();
      });
    });
    resizeObserver.observe(elementReference.current);

    const rootStyleObserver = new MutationObserver(trySetMaxFontSize);
    rootStyleObserver.observe(document.documentElement, {
      attributeFilter: ['style'],
    });

    function trySetMaxFontSize(): void {
      if (!elementReference.current) {
        return;
      }

      // Reset potential font size set by setMaxFontSize
      elementReference.current.style.fontSize = '';

      setMaxFontSize(
        elementReference.current,
        singleLine ?? false,
        reservedSpace
      );
    }

    return () => {
      resizeObserver.disconnect();
      rootStyleObserver.disconnect();
    };
  }, [elementReference, singleLine, enabled, reservedSpace]);
}
