import { useEffect, useMemo, useState } from 'react';

type ItemId = string;

export interface PaginationOptions<TItem> {
  /** The direction elements will be added in, defaults to vertical. */
  elementFlow?: 'vertical' | 'horizontal';

  /** If order can be ignored to pack pages with items. */
  ignoreItemOrderToPackPages?: boolean;

  /** If items in multi page scenarios should directly be replaced with most current item with same id. */
  directlyUpdateItemsWithMostCurrentItems?: boolean;

  /** Sets if only the first page should be handled and following pages discarded */
  singlePage?: boolean;

  /** Function to be used to re-sort items when changing page. */
  pageChangeSortingFunction?: (first: TItem, second: TItem) => number;
}

export default function usePagination<
  TItem extends object,
  TElement extends HTMLElement
>(
  availableSize: number | undefined,
  mostCurrentItems: TItem[],
  itemElementsById: Map<ItemId, TElement>,
  idPropertyName: keyof TItem,
  {
    elementFlow,
    ignoreItemOrderToPackPages,
    directlyUpdateItemsWithMostCurrentItems,
    singlePage,
    pageChangeSortingFunction,
  }: PaginationOptions<TItem> = {}
) {
  const [items, setItems] = useState<TItem[]>(
    structuredClone(mostCurrentItems)
  );
  const itemsById = useMemo(() => {
    return new Map<ItemId, TItem>(
      items.map((item) => [item[idPropertyName] as ItemId, item])
    );
  }, [idPropertyName, items]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [itemIdPages, setItemIdPages] = useState<Array<ItemId[]>>([[]]);
  const [itemIdsShown, setItemIdsShown] = useState<ItemId[]>([]);
  const pages = itemIdPages.length;
  const [paginationItemIdSuffix, setPaginationItemIdSuffix] = useState(0); // Pagination item id suffix is used as suffix because items might move from last page to first, which would mess up animations as the item would already be present on first page at start
  const itemsForCurrentPage = useMemo(() => {
    const idsForCurrentPage = itemIdPages[currentPageIndex];
    if (idsForCurrentPage === undefined) {
      return [];
    }
    return idsForCurrentPage.reduce((acc, itemId) => {
      const item = itemsById.get(itemId);
      if (item) {
        acc.push([item, itemId + paginationItemIdSuffix.toString()]);
      }
      return acc;
    }, new Array<[TItem, string]>());
  }, [currentPageIndex, itemIdPages, itemsById, paginationItemIdSuffix]);

  useEffect(() => {
    if (pages <= 1) {
      setItems(structuredClone(mostCurrentItems));
    } else if (directlyUpdateItemsWithMostCurrentItems) {
      setItems((items) => {
        const newItems = structuredClone(items);
        newItems.forEach((item, itemIndex) => {
          const mostCurrentItem = mostCurrentItems.find(
            (mostCurrentItemCandidate) =>
              mostCurrentItemCandidate[idPropertyName] === item[idPropertyName]
          );
          if (!mostCurrentItem) {
            return;
          }

          newItems[itemIndex] = structuredClone(mostCurrentItem);
        });
        return newItems;
      });
    }
  }, [
    directlyUpdateItemsWithMostCurrentItems,
    idPropertyName,
    mostCurrentItems,
    pages,
  ]);

  useEffect(() => {
    const itemElementsResizeObserver = new ResizeObserver(() => {
      recalculatePagination();
    });
    for (const itemElement of itemElementsById.values()) {
      itemElementsResizeObserver.observe(itemElement);
    }

    recalculatePagination();

    function recalculatePagination(): void {
      if (availableSize === undefined || !itemsAndElementsAreSynced()) {
        return;
      }

      const newItemIdsPages = new Array<ItemId[]>();
      let itemIds = items.map((item) => getItemId(item));
      while (itemIds.length > 0) {
        const newPage = new Array<ItemId>();
        let availableSizeLeft: number = availableSize;

        for (const itemId of itemIds) {
          const itemElement = itemElementsById.get(itemId);

          if (!itemElement) {
            throw new Error(
              `Could not find element for item with id ${itemId}, items and elements are expected to be in sync.`
            );
          }

          const itemElementSize = itemElement.getBoundingClientRect();
          const nextItemSize =
            !elementFlow || elementFlow === 'vertical'
              ? itemElementSize.height
              : itemElementSize.width;

          // Experienced rounding issue for percentage sizes while zoomed without the 0.1 marginal
          if (
            availableSizeLeft < nextItemSize - 0.1 &&
            availableSizeLeft != availableSize
          ) {
            if (ignoreItemOrderToPackPages) {
              continue;
            } else {
              break;
            }
          }

          let elementGap = 0;
          if (itemElement.parentElement) {
            const gapPropertyName =
              !elementFlow || elementFlow === 'vertical'
                ? 'row-gap'
                : 'column-gap';
            const elementGapValue = window
              .getComputedStyle(itemElement.parentElement)
              .getPropertyValue(gapPropertyName);
            elementGap =
              elementGapValue && elementGapValue !== 'normal'
                ? parseFloat(elementGapValue)
                : 0;
          }

          availableSizeLeft -= nextItemSize + elementGap;
          newPage.push(itemId);
        }

        newItemIdsPages.push(newPage);

        if (singlePage) {
          break;
        }

        itemIds = itemIds.filter((itemId) => !newPage.includes(itemId));
      }

      let newPageIndex = 0;

      // Skip already shown pages
      if (newItemIdsPages.length > 1 && itemIdsShown.length > 0) {
        for (
          let pageIndex = 0;
          pageIndex < newItemIdsPages.length;
          pageIndex++
        ) {
          const itemIdsForPage = newItemIdsPages[pageIndex];
          if (
            itemIdsForPage !== undefined &&
            itemIdsForPage.length > 0 &&
            itemIdsForPage.every((pageItemId) =>
              itemIdsShown.includes(pageItemId)
            )
          ) {
            newPageIndex++;
          } else {
            break;
          }
        }
      }

      setItemIdPages(newItemIdsPages);

      setCurrentPageIndex(newPageIndex);
    }

    function itemsAndElementsAreSynced(): boolean {
      if (items.length !== itemElementsById.size) {
        return false;
      }

      for (const item of items) {
        const itemId = getItemId(item);
        if (!itemElementsById.get(itemId)) {
          return false;
        }
      }

      return true;
    }

    function getItemId(item: TItem): string {
      const itemId = item[idPropertyName];
      if (typeof itemId !== 'string') {
        throw new Error(`Item id property is expected to be of string type.`);
      }
      if (!itemId) {
        throw new Error(
          `Encountered an item without id property for ${idPropertyName.toString()}.`
        );
      }
      return itemId;
    }

    return () => {
      itemElementsResizeObserver.disconnect();
    };
  }, [
    availableSize,
    elementFlow,
    idPropertyName,
    ignoreItemOrderToPackPages,
    itemElementsById,
    itemIdsShown,
    items,
    singlePage,
  ]);

  function nextPage() {
    if (currentPageIndex + 1 >= itemIdPages.length) {
      // Only re-calculate if more than one page
      if (pages > 1) {
        returnToFirstPage();
      }
    } else {
      if (pageChangeSortingFunction) {
        setItemIdPages((itemIdPages) => {
          const newItemIdPages = structuredClone(itemIdPages);
          const itemIdsForNextPage = newItemIdPages[currentPageIndex + 1];
          if (itemIdsForNextPage) {
            itemIdsForNextPage.sort((firstId, secondId) => {
              const firstItem = itemsById.get(firstId);
              const secondItem = itemsById.get(secondId);

              if (!firstItem || !secondItem) {
                throw new Error('Expected items not found.');
              }

              return pageChangeSortingFunction(firstItem, secondItem);
            });
          }

          return newItemIdPages;
        });
        const newItemIdsOrder = new Array<ItemId>().concat(...itemIdPages);
        setItems(
          newItemIdsOrder.map((itemId) => {
            const item = itemsById.get(itemId);
            if (!item) {
              throw new Error('Expected item not found.');
            }
            return item;
          })
        );
      }

      setItemIdsShown((itemIdsShown) => {
        const itemIdsForCurrentPage = itemIdPages[currentPageIndex] ?? [];
        return itemIdsShown.concat(itemIdsForCurrentPage);
      });
      setCurrentPageIndex(currentPageIndex + 1);
    }
  }

  function returnToFirstPage(): void {
    setCurrentPageIndex(0);

    setPaginationItemIdSuffix(
      (paginationItemIdSuffix) => (paginationItemIdSuffix + 1) % 2
    );

    setItems(structuredClone(mostCurrentItems));

    setItemIdsShown([]);
  }

  return {
    items,
    itemsForCurrentPage,
    itemIdPages,
    currentPageIndex,
    pages,
    nextPage,
  };
}
