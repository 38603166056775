import { StopArea } from '../models/stopArea';

export async function getStopAreaByStopAreaGid(
  stopAreaGid: string,
  signal: AbortSignal
): Promise<StopArea | undefined> {
  const response = await fetch(`/api/stopAreas/${stopAreaGid}`, { signal });
  if (response.ok) {
    return <StopArea>await response.json();
  }

  return undefined;
}

export async function getStopAreaByStopPointGid(
  stopPointGid: string,
  signal: AbortSignal
): Promise<StopArea | undefined> {
  const response = await fetch(`/api/stopAreas?stopPointGid=${stopPointGid}`, {
    signal,
  });
  if (response.ok) {
    return <StopArea>await response.json();
  }

  return undefined;
}

export async function searchStopAreas(
  searchText: string,
  signal: AbortSignal
): Promise<StopArea[]> {
  if (!searchText) {
    return [];
  }

  const response = await fetch(`/api/stopAreas?search=${searchText}`, {
    signal,
  });

  if (response.ok) {
    return <StopArea[]>await response.json();
  }

  return [];
}
