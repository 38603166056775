import Boards from '@shared/components/Boards';
import { Route, Routes } from 'react-router-dom';
import Help from '@shared/components/Help';
import Configuration from './modules/configuration/components/Configuration';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Boards />} />
      <Route path="/help" element={<Help />} />
      <Route path="/configuration" element={<Configuration />} />
    </Routes>
  );
}

export default App;
