import { DepartureRow } from '../models/departureRow';
import { DepartureSorting } from '@shared/models/departureSorting';

export function compareDepartureRows(
  first: DepartureRow,
  second: DepartureRow,
  sorting: DepartureSorting
): number {
  function getComparisonTime(departureRow: DepartureRow): number | undefined {
    if (!departureRow.next.isCancelled) {
      return departureRow.next.estimatedOtherwisePlannedTime.getTime();
    }

    if (departureRow.thereafter && !departureRow.thereafter.isCancelled) {
      return departureRow.thereafter.estimatedOtherwisePlannedTime.getTime();
    }

    return undefined;
  }

  if (sorting === 'time') {
    const firstTime = getComparisonTime(first);
    const secondTime = getComparisonTime(second);

    if (
      firstTime !== undefined &&
      firstTime === secondTime &&
      first.next.isCancelled !== second.next.isCancelled
    ) {
      return first.next.isCancelled ? -1 : 1;
    }

    if (
      firstTime !== undefined &&
      secondTime !== undefined &&
      firstTime !== secondTime
    ) {
      return firstTime - secondTime;
    }

    if (firstTime && secondTime === undefined) {
      return -1;
    }

    if (firstTime === undefined && secondTime) {
      return 1;
    }
  }

  const firstNumeric = parseInt(first.line.shortName, 10);
  const secondNumeric = parseInt(second.line.shortName, 10);
  if (!Number.isNaN(firstNumeric) && Number.isNaN(secondNumeric)) {
    return -1;
  }

  if (Number.isNaN(firstNumeric) && !Number.isNaN(secondNumeric)) {
    return 1;
  }

  if (
    !Number.isNaN(firstNumeric) &&
    !Number.isNaN(secondNumeric) &&
    firstNumeric !== secondNumeric
  ) {
    return firstNumeric - secondNumeric;
  }

  return (
    first.line.shortName.localeCompare(second.line.shortName) ||
    first.direction.name.localeCompare(second.direction.name) ||
    (first.direction.via == null && second.direction.via != null ? -1 : 0) ||
    (first.direction.via != null && second.direction.via != null
      ? first.direction.via.localeCompare(second.direction.via)
      : 0)
  );
}
