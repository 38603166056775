import { ChangeEvent } from 'react';
import { StopArea } from '@shared/models/stopArea';
import styles from './StopPointsConfigurator.module.scss';
import classNames from 'classnames';

export type StopPointGids = 'all' | string[];

interface StopPointConfiguratorProps {
  stopArea: StopArea;
  stopPointGids: StopPointGids;
  onChange: (stopPointGids: StopPointGids) => void;
}

export default function StopPointsConfigurator({
  stopArea,
  stopPointGids,
  onChange,
}: StopPointConfiguratorProps) {
  function onAllStopPointGidsChange(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.checked) {
      return;
    }

    onChange('all');
  }

  function onStopPointGidChange(event: ChangeEvent<HTMLInputElement>) {
    const stopPointGid = event.target.value;

    if (event.target.checked) {
      const newStopPointGids =
        stopPointGids === 'all'
          ? [stopPointGid]
          : [...stopPointGids, stopPointGid];
      onChange(newStopPointGids);
    } else {
      onChange(getNewStopPointGids(stopPointGid));
    }
  }

  function getNewStopPointGids(removedStopPointGid: string): StopPointGids {
    if (stopPointGids === 'all') {
      return 'all';
    }

    const newStopPointGids = stopPointGids.filter(
      (stopPointGid) => stopPointGid !== removedStopPointGid
    );

    if (newStopPointGids.length === 0) {
      return 'all';
    }

    return newStopPointGids;
  }

  return (
    <>
      <label className={classNames(styles.option, styles['option--all'])}>
        <input
          type="checkbox"
          checked={stopPointGids === 'all'}
          disabled={stopPointGids === 'all'}
          onChange={onAllStopPointGidsChange}
        />
        Alla
      </label>
      {stopArea.stopPoints.map((stopPoint) => (
        <label key={stopPoint.gid} className={styles.option}>
          <input
            type="checkbox"
            checked={
              stopPointGids !== 'all' && stopPointGids.includes(stopPoint.gid)
            }
            value={stopPoint.gid}
            onChange={onStopPointGidChange}
          />
          {stopPoint.designation}
        </label>
      ))}
    </>
  );
}
