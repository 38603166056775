import * as jsonpatch from 'fast-json-patch';
import { useEffect, useRef, useState } from 'react';
import { DepartureRow } from '../../modules/departures/models/departureRow';
import {
  DEPARTURE_PAGING_DEFAULT,
  DEPARTURE_TIME_FORMAT_DEFAULT,
  DEPARTURES_TIME_SPAN_DEFAULT,
  SECONDS_BETWEEN_ALIVE_MESSAGES,
  SECONDS_BETWEEN_EVENT_SOURCE_RECONNECTION_ATTEMPTS,
  SECONDS_OF_ERROR_BEFORE_SHOWING_ERROR_MESSAGE,
  SECONDS_OF_MAX_EXPECTED_SERVER_STARTUP_TIME,
  SECONDS_OF_MAXIMUM_EXPECTED_LATENCY,
} from '../constants';
import { VERSION } from '../../version';
import { dateReviver } from '../utils/dateReviver';
import {
  BoardConfiguration,
  ConfigurationId,
} from '../models/boardConfiguration';
import { DepartureRowsError } from '../../modules/departures/models/events/departureRowsError';
import { ResourcesSet } from '../models/events/resourcesSet';
import { NewStopBoardHeader } from '../models/events/newStopBoardHeader';
import { StopBoardHeader } from '../models/stopBoardHeader';
import { isBoardEvent } from '../utils/isBoardEvent';
import { DepartureRowsErrorResolved } from '../../modules/departures/models/events/departureRowsErrorResolved';
import { BoardEvent } from '../models/boardEvent';
import { ResourcesAdded } from '../models/events/resourcesAdded';
import { ResourcesUpdated } from '../models/events/resourcesUpdated';
import { Resource } from '../models/resource';
import { ResourcesRemoved } from '../models/events/resourcesRemoved';
import { TrafficSituation } from '../../modules/traffic-situations/models/trafficSituation';
import { BoardId } from '../models/boardId';
import { logError } from '../utils/errorLogging';

export interface StopBoardState {
  boardId: BoardId;
  configurationId?: ConfigurationId;
  header?: StopBoardHeader;
  departureRows?: DepartureRow[];
  departureRowsError?: boolean;
  trafficSituations?: TrafficSituation[];
}

export function useResourceManager(boards: BoardConfiguration[] | undefined) {
  const boardStatesForConfiguration = useRef<string | undefined>(undefined);
  const [boardStates, setBoardStates] = useState(
    new Map<BoardId, StopBoardState>()
  );

  const hasConnectionErrorTimerId = useRef<number | undefined>(undefined);
  const [hasConnectionError, setHasConnectionError] = useState(false);
  const [connectionErrorCount, setConnectionErrorCount] = useState(0);

  function setHasConnectionErrorTimer() {
    if (hasConnectionErrorTimerId.current) {
      return;
    }
    hasConnectionErrorTimerId.current = window.setTimeout(() => {
      setHasConnectionError(true);
    }, SECONDS_OF_ERROR_BEFORE_SHOWING_ERROR_MESSAGE * 1000);
  }

  function clearHasConnectionErrorTimer() {
    window.clearTimeout(hasConnectionErrorTimerId.current);
    hasConnectionErrorTimerId.current = undefined;
  }

  useEffect(() => {
    if (!boards || boards.length === 0) {
      return;
    }

    let lastAliveMessageTime = Date.now();

    let retryConnectionTimerId: number | undefined;
    const boardDepartureRowsErrorTimerIds = new Array<number | undefined>();

    function retryConnection() {
      setConnectionErrorCount((previousErrorCount) => previousErrorCount + 1);
    }

    function closeWaitAndRetryConnection(error: string) {
      console.error(
        `Resetting connection in ${SECONDS_BETWEEN_EVENT_SOURCE_RECONNECTION_ATTEMPTS} seconds due to error: ${error}`
      );

      eventSource.close();

      setRetryConnectionTimer();
    }

    function setRetryConnectionTimer() {
      if (retryConnectionTimerId) {
        return;
      }

      retryConnectionTimerId = window.setTimeout(() => {
        retryConnection();
        retryConnectionTimerId = undefined;
      }, SECONDS_BETWEEN_EVENT_SOURCE_RECONNECTION_ATTEMPTS * 1000);
    }

    function getOrCreateBoardState(
      boardId: BoardId,
      boardStates: Map<BoardId, StopBoardState>
    ): StopBoardState {
      let boardState = boardStates.get(boardId);
      if (!boardState) {
        boardState = { boardId: boardId, configurationId: boardId };
        boardStates.set(boardId, boardState);
      }

      return boardState;
    }

    function getEvent<TEvent>(data: unknown): TEvent | undefined {
      if (!data || typeof data !== 'string') {
        return undefined;
      }
      try {
        const event: unknown = JSON.parse(data, dateReviver);
        return isBoardEvent<TEvent>(event) ? event : undefined;
      } catch (error) {
        logError(error);
        return undefined;
      }
    }

    function handleBoardEvent<TEvent extends BoardEvent>(
      e: MessageEvent<unknown>,
      eventHandler: (event: TEvent, boardState: StopBoardState) => void
    ): void {
      const event = getEvent<TEvent>(e.data);
      if (!event) {
        closeWaitAndRetryConnection('Failed to parse event');
        return;
      }
      setBoardStates((previousBoardStates) => {
        const newBoardStates = structuredClone(previousBoardStates);
        const boardState = getOrCreateBoardState(event.board, newBoardStates);
        eventHandler(event, boardState);
        return newBoardStates;
      });
    }

    function registerResourceEventHandlers<TResource extends Resource>(
      resourceName: 'departureRow' | 'trafficSituation',
      getResources: (
        boardState: StopBoardState
      ) => readonly TResource[] | undefined,
      setResources: (
        boardState: StopBoardState,
        newResources: TResource[]
      ) => void
    ): void {
      eventSource.addEventListener(`${resourceName}sSet`, (e) => {
        handleBoardEvent<ResourcesSet<TResource>>(e, (event, boardState) => {
          setResources(boardState, event.set);
          if (resourceName === 'departureRow' && event.error) {
            boardState.departureRowsError = true;
          }
        });
      });

      eventSource.addEventListener(`${resourceName}sAdded`, (e) => {
        handleBoardEvent<ResourcesAdded<TResource>>(e, (event, boardState) => {
          const resources = getResources(boardState);
          if (!resources) {
            setResources(boardState, [...event.added]);
          } else {
            setResources(boardState, [...resources, ...event.added]);
          }
        });
      });

      eventSource.addEventListener(`${resourceName}sUpdated`, (e) => {
        handleBoardEvent<ResourcesUpdated>(e, (event, boardState) => {
          const resources = getResources(boardState);
          if (!resources) {
            closeWaitAndRetryConnection(
              `Expected ${resourceName}s not found for board ${event.board}`
            );
            return;
          }

          const newResources = [...structuredClone(resources)];

          for (const update of event.updates) {
            const [id, patch] = update;
            const resource = newResources.find((row) => row.id === id);
            if (resource === undefined) {
              closeWaitAndRetryConnection(
                `Expected ${resourceName} not found ${id}`
              );
              return;
            }

            jsonpatch.applyPatch(resource, patch).newDocument;
          }
          setResources(boardState, newResources);
        });
      });

      eventSource.addEventListener(`${resourceName}sRemoved`, (e) => {
        handleBoardEvent<ResourcesRemoved>(e, (event, boardState) => {
          const resources = getResources(boardState);
          if (!resources) {
            closeWaitAndRetryConnection(
              `Expected ${resourceName}s not found for board ${event.board}`
            );
            return;
          }

          const newResources = resources.filter(
            (resource) => !event.ids.includes(resource.id)
          );
          setResources(boardState, newResources);
        });
      });
    }

    function addGid(
      urlSearchParams: URLSearchParams,
      boardId: BoardId,
      gid: string
    ) {
      urlSearchParams.append(`board${boardId}Gids`, gid);
    }

    const checkEventsAliveIntervalId = window.setInterval(() => {
      const secondsSinceLastAliveMessage =
        (Date.now() - lastAliveMessageTime) / 1000;
      if (
        secondsSinceLastAliveMessage >=
        SECONDS_BETWEEN_ALIVE_MESSAGES + SECONDS_OF_MAXIMUM_EXPECTED_LATENCY
      ) {
        setHasConnectionErrorTimer();
        retryConnection();
      }
    }, (SECONDS_BETWEEN_ALIVE_MESSAGES / 10) * 1000);

    const eventsUrlSearchParams = new URLSearchParams();
    for (const board of boards) {
      if (board.type === 'stop-area') {
        addGid(eventsUrlSearchParams, board.id, board.stopAreaGid);
      } else {
        for (const stopPointGid of board.stopPointGids) {
          addGid(eventsUrlSearchParams, board.id, stopPointGid);
        }
      }
      for (const module of board.modules) {
        eventsUrlSearchParams.append(`board${board.id}Modules`, module);
      }
      if (
        board.departurePaging !== undefined &&
        board.departurePaging !== DEPARTURE_PAGING_DEFAULT
      ) {
        eventsUrlSearchParams.append(
          `board${board.id}DeparturePaging`,
          board.departurePaging.toString()
        );
      }
      if (
        board.departureTimeFormat !== undefined &&
        board.departureTimeFormat !== DEPARTURE_TIME_FORMAT_DEFAULT
      ) {
        eventsUrlSearchParams.append(
          `board${board.id}DepartureTimeFormat`,
          board.departureTimeFormat
        );
      }
      if (
        board.departureTimeSpan !== undefined &&
        board.departureTimeSpan !== DEPARTURES_TIME_SPAN_DEFAULT
      ) {
        eventsUrlSearchParams.append(
          `board${board.id}DepartureTimeSpan`,
          board.departureTimeSpan
        );
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    if (id) {
      eventsUrlSearchParams.append('id', id);
    }
    const type = urlParams.get('type');
    if (type) {
      eventsUrlSearchParams.append('type', type);
    }

    const eventsUrl = `api/events?${eventsUrlSearchParams.toString()}`;

    if (boardStatesForConfiguration.current !== eventsUrl) {
      setBoardStates(new Map<BoardId, StopBoardState>());
      boardStatesForConfiguration.current = eventsUrl;
    }

    const eventSource = new EventSource(eventsUrl);

    eventSource.addEventListener('open', () => {
      lastAliveMessageTime = Date.now();
      setHasConnectionError(false);
      clearHasConnectionErrorTimer();
    });

    eventSource.addEventListener('error', () => {
      // Retry if the event source is closed
      // This is meant to handle 404 and 500 response returned on server issues
      // This will unfortunately also catch 400 response on bad configuration, if this turns out to be an issue we could use @microsoft/fetch-event-source instead
      if (eventSource.readyState === EventSource.CLOSED) {
        setHasConnectionErrorTimer();
        setRetryConnectionTimer();
      }
    });

    eventSource.addEventListener('version', (e) => {
      // Reload on non-matching version
      if (e.data !== VERSION) {
        setTimeout(() => {
          location.reload();
        }, SECONDS_OF_MAX_EXPECTED_SERVER_STARTUP_TIME * 1000);
      }
    });

    eventSource.addEventListener('message', () => {
      // Empty messages with no event field are used as alive message to know early if connection is lost
      lastAliveMessageTime = Date.now();
    });

    eventSource.addEventListener('newStopBoardHeader', (e) => {
      handleBoardEvent<NewStopBoardHeader>(e, (event, boardState) => {
        boardState.header = event.header;
      });
    });

    eventSource.addEventListener('departureRowsError', (e) => {
      handleBoardEvent<DepartureRowsError>(e, (event) => {
        const boardId = event.board;
        const timerId = boardDepartureRowsErrorTimerIds[boardId];
        if (timerId) {
          return;
        }
        boardDepartureRowsErrorTimerIds[boardId] = window.setTimeout(() => {
          handleBoardEvent<DepartureRowsError>(e, (_, boardState) => {
            boardState.departureRowsError = true;
          });
        }, SECONDS_OF_ERROR_BEFORE_SHOWING_ERROR_MESSAGE * 1000);
      });
    });

    eventSource.addEventListener('departureRowsErrorResolved', (e) => {
      handleBoardEvent<DepartureRowsErrorResolved>(e, (event, boardState) => {
        const timerId = boardDepartureRowsErrorTimerIds[event.board];
        if (timerId) {
          window.clearTimeout(timerId);
          boardDepartureRowsErrorTimerIds[event.board] = undefined;
        }

        boardState.departureRowsError = undefined;
      });
    });

    registerResourceEventHandlers(
      'departureRow',
      (boardState) => boardState.departureRows,
      (boardState, newDepartureRows) =>
        (boardState.departureRows = newDepartureRows)
    );
    registerResourceEventHandlers(
      'trafficSituation',
      (boardState) => boardState.trafficSituations,
      (boardState, newtrafficSituations) =>
        (boardState.trafficSituations = newtrafficSituations)
    );

    return () => {
      eventSource.close();
      window.clearTimeout(retryConnectionTimerId);
      window.clearInterval(checkEventsAliveIntervalId);
      for (const timerId of boardDepartureRowsErrorTimerIds) {
        if (!timerId) {
          continue;
        }
        window.clearTimeout(timerId);
      }
    };
  }, [boards, connectionErrorCount]);

  return { hasConnectionError, boardStates: [...boardStates.values()] };
}
