import classNames from 'classnames';
import styles from './TrafficSituationDescription.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  CHARACTERS_READ_PER_SECOND,
  MINIMUM_TIME_IN_SECONDS_TO_READ_TEXT,
  SCROLL_TIME_IN_SECONDS,
} from '@shared/constants';
import useElementSize from '@shared/hooks/useElementSize';
import { Tween, Easing, Group } from '@tweenjs/tween.js';

interface TrafficSituationDescriptionProps {
  availableHeight: number | undefined;
  text: string;
  timeToReadTitleInSeconds: number;
  viewTimeUpdated?: (newViewTime: number) => void;
}

export default function TrafficSituationDescription({
  availableHeight,
  text,
  timeToReadTitleInSeconds,
  viewTimeUpdated,
}: TrafficSituationDescriptionProps) {
  const descriptionRef = useRef<HTMLDivElement>(null);
  const descriptionTextRef = useRef<HTMLParagraphElement>(null);
  const descriptionTextSize = useElementSize(descriptionTextRef);
  const pagingRef = useRef<HTMLDivElement>(null);
  const pagingSize = useElementSize(pagingRef, { includeMargin: true });

  const [page, setPage] = useState(0);
  const lineHeight = descriptionRef.current
    ? parseFloat(
        window
          .getComputedStyle(descriptionRef.current)
          .getPropertyValue('line-height')
      )
    : 0;

  const heightAvailableForDescriptionText =
    availableHeight && pagingSize.height
      ? availableHeight - pagingSize.height
      : undefined;

  // Adding 1 to height to avoid rounding errors
  const pagingStepHeight =
    lineHeight > 0 && heightAvailableForDescriptionText !== undefined
      ? Math.max(
          Math.floor((heightAvailableForDescriptionText + 1) / lineHeight) *
            lineHeight,
          lineHeight
        )
      : 1;
  const useFading = pagingStepHeight > lineHeight;

  const fadingHeight = lineHeight * 0.5; // The multiplier should be in sync with multiplier for --traffic-situation-description-fade-height'

  function getPageDisplayTimes(): number[] {
    const newPageDisplayTimes = new Array<number>();
    if (
      !descriptionRef.current ||
      !descriptionTextRef.current ||
      descriptionTextSize.height === undefined
    ) {
      return newPageDisplayTimes;
    }

    let remainingHeight = descriptionTextSize.height;
    while (remainingHeight >= 1) {
      const pageIsOneLineOrLess = remainingHeight <= lineHeight + 1;
      const pageHeight = Math.min(remainingHeight, pagingStepHeight);
      remainingHeight -= pageHeight;
      // Assume evenly distributed text per line
      let pageDisplayTime =
        MINIMUM_TIME_IN_SECONDS_TO_READ_TEXT +
        ((pageHeight / descriptionTextSize.height) * text.length) /
          CHARACTERS_READ_PER_SECOND;
      // Add title read time to first page
      if (newPageDisplayTimes.length === 0) {
        pageDisplayTime += timeToReadTitleInSeconds;
      }
      // Allow for one line of overflow in no-paging scenarios
      if (newPageDisplayTimes.length === 1 && pageIsOneLineOrLess) {
        const firstPageDisplayTime = newPageDisplayTimes[0];
        if (!firstPageDisplayTime) {
          break;
        }
        newPageDisplayTimes[0] = firstPageDisplayTime + pageDisplayTime;
        break;
      }
      newPageDisplayTimes.push(pageDisplayTime);
    }

    return newPageDisplayTimes;
  }
  const pageDisplayTimes = getPageDisplayTimes();
  const previousViewTime = useRef<number | undefined>();
  useEffect(() => {
    if (viewTimeUpdated) {
      const newDisplayTime = pageDisplayTimes.reduce((a, b) => a + b, 0);
      if (newDisplayTime !== 0 && newDisplayTime !== previousViewTime.current) {
        previousViewTime.current = newDisplayTime;
        viewTimeUpdated(newDisplayTime);
      }
    }
  }, [pageDisplayTimes, viewTimeUpdated]);

  const isPaginated = pageDisplayTimes.length > 1;
  const hasPreviousPage = isPaginated && page > 0;
  const hasNextPage = pageDisplayTimes.length > page + 1;
  const scrollTop =
    page * pagingStepHeight -
    (useFading && hasPreviousPage && hasNextPage ? fadingHeight : 0);

  useEffect(() => {
    const scrollElement = descriptionRef.current;
    if (!scrollElement) {
      return;
    }

    const group = new Group();
    const position = { scrollTop: scrollElement.scrollTop };
    const tween = new Tween(position)
      .to({ scrollTop: scrollTop }, SCROLL_TIME_IN_SECONDS * 1000)
      .easing(Easing.Quadratic.Out)
      .onUpdate(() => {
        scrollElement.scrollTop = position.scrollTop;
      })
      .onComplete(() => {
        animating = false;
      })
      .start();
    group.add(tween);

    let animating = true;
    function animate(time: number) {
      if (!animating) {
        return;
      }
      group.update(time);
      requestAnimationFrame(animate);
    }
    requestAnimationFrame(animate);

    return () => {
      animating = false;
    };
  }, [scrollTop]);

  const onPaginationAnimationEnd = useCallback(() => {
    if (!descriptionRef.current || pageDisplayTimes.length <= 1) {
      return;
    }

    if (page + 1 >= pageDisplayTimes.length) {
      setPage(0);
    } else {
      setPage(page + 1);
    }
  }, [page, pageDisplayTimes]);

  return (
    <>
      <div
        className={classNames(
          styles['description-container'],
          isPaginated && styles['description-container--paginated'],
          useFading &&
            hasPreviousPage &&
            styles['description-container--has-previous-page'],
          useFading &&
            hasNextPage &&
            styles['description-container--has-next-page']
        )}
      >
        <div className={styles.description} ref={descriptionRef}>
          <p className={styles.description__text} ref={descriptionTextRef}>
            {text}
          </p>
        </div>
      </div>
      <div
        ref={pagingRef}
        className={styles['traffic-situation__paging']}
        aria-hidden="true"
      >
        {pageDisplayTimes.length > 1 &&
          pageDisplayTimes.map((pageDisplayTime, pageIndex) => (
            <span
              className={styles.page}
              key={pageIndex}
              style={{ flexGrow: pageDisplayTime }}
            >
              <span
                className={classNames(
                  styles['page-progress'],
                  page === pageIndex && styles['page-progress--fill'],
                  page > pageIndex && styles['page-progress--filled']
                )}
                style={{ animationDuration: `${pageDisplayTime}s` }}
                onAnimationEnd={onPaginationAnimationEnd}
              ></span>
            </span>
          ))}
      </div>
    </>
  );
}
