import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './styles/main.scss';
import { BrowserRouter } from 'react-router-dom';
import { logError } from '@shared/utils/errorLogging.ts';

const root = document.getElementById('root');

window.onerror = function (message, source, lineno, colno, error) {
  const errorData = {
    message,
    source,
    lineno,
    colno,
    stack: error?.stack,
  };

  logError(errorData);
};

if (root) {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}
