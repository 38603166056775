import { RefObject } from 'react';

export function preparePageExitAnimations(
  currentPageElementRefs: React.MutableRefObject<
    Map<string, React.RefObject<HTMLElement>>
  >
): void {
  for (const currentPageElementRef of currentPageElementRefs.current.values()) {
    prepareExitAnimation(currentPageElementRef);
  }
}

export function prepareExitAnimation(elementRef: RefObject<HTMLElement>): void {
  if (!elementRef.current) {
    return;
  }
  const containerClientRect = elementRef.current.getBoundingClientRect();
  elementRef.current.style.setProperty(
    '--exit-left',
    `${containerClientRect.left}px`
  );
  elementRef.current.style.setProperty(
    '--exit-top',
    `${containerClientRect.top}px`
  );

  elementRef.current.style.setProperty(
    '--exit-width',
    `${containerClientRect.width}px`
  );
  elementRef.current.style.setProperty(
    '--exit-height',
    `${containerClientRect.height}px`
  );
}
