import { getEnvironment } from './environment';

const environment = getEnvironment();
const isSupportingErrorLogging =
  environment === 'Development' ||
  environment === 'Test' ||
  environment === 'UAT';

export function logError(error: unknown): void {
  if (!isSupportingErrorLogging || error == null) {
    return;
  }

  const errorData = {
    error,
    configuration: window.location.search,
  };

  logErrorMessage(JSON.stringify(errorData));
}

function logErrorMessage(message: string): void {
  void fetch('/api/errors', {
    method: 'POST',
    headers: { 'Content-Type': 'text/plain' },
    body: message,
  });
}
