import ImageIcon from './ImageIcon';
import styles from './LoadingPanel.module.scss';

export default function LoadingPanel() {
  return (
    <div className={styles.container}>
      <ImageIcon type="spinner" alt="Laddar" className={styles.spinner} />
    </div>
  );
}
