import { StopBoardState } from '../hooks/useResourceManager';
import { StopBoardConfiguration } from '../models/boardConfiguration';
import DeparturesModule from '../../modules/departures/components/DeparturesModule';
import StopBoardHeader from './StopBoardHeader';
import TrafficSituationsModule from '../../modules/traffic-situations/components/TrafficSituationsModule';
import styles from './Board.module.scss';
import classNames from 'classnames';
import ImageIcon from './ImageIcon';
import { BoardContext } from './Boards';
import { createContext, useContext, useRef } from 'react';
import { Layout } from '../models/layout';
import {
  HORIZONTAL_BOARD_MINIMUM_ASPECT_RATIO,
  SHOW_PLATFORMS_IN_HEADER_DEFAULT,
} from '../constants';
import useElementSize from '../hooks/useElementSize';
import { TrafficSituation } from '../../modules/traffic-situations/models/trafficSituation';

interface BoardProps {
  hasConnectionError: boolean;
  boardConfiguration: StopBoardConfiguration;
  boardState?: StopBoardState;
}

export const LayoutContext = createContext<Layout>('horizontal');

export default function Board({
  hasConnectionError,
  boardConfiguration,
  boardState,
}: BoardProps) {
  const boardRef = useRef<HTMLDivElement>(null);
  const boardSize = useElementSize(boardRef);
  const layout =
    boardSize.width === undefined ||
    boardSize.height === undefined ||
    boardSize.width > boardSize.height * HORIZONTAL_BOARD_MINIMUM_ASPECT_RATIO
      ? 'horizontal'
      : 'vertical';

  const renderDeparturesModule =
    boardConfiguration.modules.includes('departures');
  const boardContext = useContext(BoardContext);
  const renderBranding = boardContext.isLast;
  const showPlatformsInHeader =
    boardConfiguration.type === 'stop-points' &&
    (boardConfiguration.showPlatformsInHeader ??
      SHOW_PLATFORMS_IN_HEADER_DEFAULT);

  function shouldRenderTrafficSituationsModule(
    trafficSituations: TrafficSituation[] | undefined
  ): trafficSituations is TrafficSituation[] {
    return (
      boardConfiguration.modules.includes('trafficSituations') &&
      trafficSituations !== undefined &&
      trafficSituations.length > 0
    );
  }

  return (
    <LayoutContext.Provider value={layout}>
      <div
        ref={boardRef}
        className={classNames(
          styles.board,
          styles[`board--${layout}`],
          styles['board--has-header'],
          renderDeparturesModule && styles['board--has-departures'],
          shouldRenderTrafficSituationsModule(boardState?.trafficSituations) &&
            styles['board--has-traffic-situations'],
          renderBranding && styles['board--has-branding']
        )}
      >
        <div className={styles.header}>
          <StopBoardHeader
            header={boardState?.header}
            showPlatforms={showPlatformsInHeader}
          />
        </div>

        {renderDeparturesModule && (
          <div className={styles.departures}>
            <DeparturesModule
              boardConfiguration={boardConfiguration}
              departureRows={boardState?.departureRows}
              departureRowsError={
                hasConnectionError || boardState?.departureRowsError
              }
            />
          </div>
        )}
        {shouldRenderTrafficSituationsModule(boardState?.trafficSituations) && (
          <div className={styles['traffic-situations']}>
            <TrafficSituationsModule
              trafficSituations={boardState.trafficSituations}
            />
          </div>
        )}

        {renderBranding && (
          <div className={styles.branding}>
            <ImageIcon
              type="branding"
              alt="Västtrafik"
              className={styles.branding__image}
            ></ImageIcon>
          </div>
        )}
      </div>
    </LayoutContext.Provider>
  );
}
