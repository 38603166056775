const stopAreaGidPrefix = 9021;
const stopPointGidPrefix = 9022;

export function isStopArea(gid: string | undefined): boolean {
  return getClassNumber(gid) === stopAreaGidPrefix;
}

export function isStopPoint(gid: string | undefined): boolean {
  return getClassNumber(gid) === stopPointGidPrefix;
}

function getClassNumber(gid: string | undefined): number | undefined {
  if (!gid || gid.length != 16) {
    return undefined;
  }

  try {
    return parseInt(gid.substring(0, 4));
  } catch {
    return undefined;
  }
}
