export function getEnvironment():
  | 'Development'
  | 'Feature'
  | 'Test'
  | 'UAT'
  | 'Production'
  | undefined {
  const hostName = window.location.hostname.toLowerCase();
  if (hostName.startsWith('localhost')) {
    return 'Development';
  } else if (
    hostName.startsWith('feature-gds') ||
    hostName.startsWith('avgangstavla-feature')
  ) {
    return 'Feature';
  } else if (
    hostName.startsWith('test-gds') ||
    hostName.startsWith('avgangstavla-test')
  ) {
    return 'Test';
  } else if (
    hostName.startsWith('uat-gds') ||
    hostName.startsWith('avgangstavla-uat')
  ) {
    return 'UAT';
  } else if (hostName.startsWith('gds') || hostName.startsWith('avgangstavla')) {
    return 'Production';
  }
  
  return undefined;
}
