import classNames from 'classnames';
import styles from './PageIndicator.module.scss';

interface PageIndicatorProps {
  currentPagePaginationTime: number;
  currentPageIndex: number;
  pages: number;
  paging: () => void;
}

export default function PageIndicator({
  currentPagePaginationTime,
  currentPageIndex,
  pages,
  paging,
}: PageIndicatorProps) {
  return (
    <div className={styles.pages}>
      {[...Array<number>(pages)].map((_, pageIndex) => {
        return (
          <span
            key={pageIndex}
            className={classNames(
              styles.page,
              pageIndex < currentPageIndex && styles['page--viewed'],
              pageIndex === currentPageIndex && styles['page--current']
            )}
          >
            {pageIndex === currentPageIndex && (
              <span
                key={currentPagePaginationTime}
                className={styles['page__progress']}
                style={{
                  animationDuration: `${currentPagePaginationTime}s`,
                }}
                onAnimationEnd={paging}
              ></span>
            )}
          </span>
        );
      })}
    </div>
  );
}
