import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  ConfigurationId,
  IncompleteStopBoardConfiguration,
  StopBoardConfiguration,
} from '@shared/models/boardConfiguration';
import { StopArea } from '@shared/models/stopArea';
import {
  getStopAreaByStopAreaGid,
  getStopAreaByStopPointGid,
} from '@shared/utils/stopAreas';
import { Module } from '@shared/models/module';
import { DepartureSorting } from '@shared/models/departureSorting';
import StopAreaSearch from './StopAreaSearch';
import StopPointsConfigurator, {
  StopPointGids,
} from './StopPointsConfigurator';
import styles from './StopBoardConfigurator.module.scss';
import { getDisplayName } from '@shared/utils/stopArea';
import {
  DEPARTURE_SORTING_DEFAULT,
  DEPARTURE_TIME_FORMAT_DEFAULT,
} from '@shared/constants';
import { DepartureTimeFormat } from '@shared/models/departureTimeFormat';
import { DepartureTimeSpan } from '@shared/models/departureTimeSpan';

export interface StopBoardConfiguratorProps {
  configuratorId: number;
  configuration?: IncompleteStopBoardConfiguration | StopBoardConfiguration;
  onChanged: (
    configuratorId: number,
    boardConfiguration: StopBoardConfiguration
  ) => void;
}

export default function StopBoardConfigurator({
  configuratorId,
  configuration,
  onChanged,
}: StopBoardConfiguratorProps) {
  const departureSortingName = `departure-sorting-${configuratorId}`;
  const departureTimeFormatName = `departure-time-format-${configuratorId}`;
  const [stopArea, setStopArea] = useState<StopArea | undefined>(undefined);

  useEffect(() => {
    const abortController = new AbortController();

    async function updateStopArea() {
      try {
        const stopArea = await getStopArea();
        setStopArea(stopArea);
      } catch (error) {
        if (error instanceof DOMException && error.name === 'AbortError') {
          // Ignore automatically aborted requests
        } else {
          throw error;
        }
      }
    }

    async function getStopArea(): Promise<StopArea | undefined> {
      if (configuration === undefined || configuration.type === 'stop') {
        return undefined;
      }

      if (configuration.type === 'stop-area') {
        return await getStopAreaByStopAreaGid(
          configuration.stopAreaGid,
          abortController.signal
        );
      }

      const stopPointGid = configuration.stopPointGids[0];
      if (!stopPointGid) {
        return undefined;
      }

      return await getStopAreaByStopPointGid(
        stopPointGid,
        abortController.signal
      );
    }

    void updateStopArea();

    return () => {
      abortController.abort();
    };
  }, [configuration]);

  const onStopAreaChange = useCallback((stopArea: StopArea) => {
    setStopArea(stopArea);
    setStopPointGids('all');
  }, []);

  const [stopPointGids, setStopPointGids] = useState<StopPointGids>(
    configuration?.type === 'stop-points' ? configuration.stopPointGids : 'all'
  );

  const [showPlatformsInHeader, setShowPlatformsInHeader] = useState(
    configuration?.type === 'stop-points' &&
      configuration.showPlatformsInHeader === false
      ? false
      : true
  );
  function onShowPlatformsInHeaderChange(event: ChangeEvent<HTMLInputElement>) {
    setShowPlatformsInHeader(event.target.checked);
  }

  const [modules, setModules] = useState<Module[]>(
    configuration !== undefined ? configuration.modules : []
  );
  function onModuleChange(event: ChangeEvent<HTMLInputElement>) {
    const module = event.target.value as Module;

    if (event.target.checked) {
      setModules((previousModules) => [...previousModules, module]);
    } else {
      setModules((previousModules) =>
        previousModules.filter((previousModule) => previousModule !== module)
      );
    }
  }

  const [departureSorting, setDepartureSorting] = useState<DepartureSorting>(
    configuration?.departureSorting !== undefined
      ? configuration.departureSorting
      : DEPARTURE_SORTING_DEFAULT
  );
  function onDepartureSortingChange(event: ChangeEvent<HTMLInputElement>) {
    setDepartureSorting(event.target.value as DepartureSorting);
  }

  const [departureTimeFormat, setDepartureTimeFormat] =
    useState<DepartureTimeFormat>(
      configuration?.departureTimeFormat !== undefined
        ? configuration.departureTimeFormat
        : DEPARTURE_TIME_FORMAT_DEFAULT
    );
  function onDepartureTimeFormatChange(event: ChangeEvent<HTMLInputElement>) {
    setDepartureTimeFormat(event.target.value as DepartureTimeFormat);
  }

  useEffect(() => {
    function getConfigurationId(): ConfigurationId {
      return configuration === undefined ? -1 : configuration.id;
    }

    function getConfiguration(
      stopArea: StopArea | undefined
    ): StopBoardConfiguration | undefined {
      if (!stopArea) {
        return undefined;
      }

      const departureSortingToUse: DepartureSorting =
        departureTimeFormat === 'time' ? 'time' : departureSorting;
      const departureTimeSpan: DepartureTimeSpan =
        departureTimeFormat === 'countdown' ? 'hour' : 'day';
      const departurePaging = departureTimeFormat === 'countdown';

      if (stopPointGids === 'all') {
        return {
          id: getConfigurationId(),
          type: 'stop-area',
          modules,
          departureSorting: departureSortingToUse,
          departureTimeFormat,
          departureTimeSpan,
          departurePaging,
          stopAreaGid: stopArea.gid,
        };
      }

      return {
        id: getConfigurationId(),
        type: 'stop-points',
        modules,
        departureSorting: departureSortingToUse,
        departureTimeFormat,
        departureTimeSpan,
        departurePaging,
        stopPointGids,
        showPlatformsInHeader,
      };
    }

    const boardConfiguration = getConfiguration(stopArea);
    if (boardConfiguration) {
      onChanged(configuratorId, boardConfiguration);
    }
  }, [
    configuratorId,
    configuration,
    departureSorting,
    modules,
    onChanged,
    showPlatformsInHeader,
    stopArea,
    stopPointGids,
    departureTimeFormat,
  ]);
  return (
    <div className={styles['stop-board-configurator']}>
      <StopAreaSearch
        autoFocus={stopArea === undefined}
        onChange={onStopAreaChange}
      ></StopAreaSearch>

      {stopArea && (
        <fieldset>
          <legend>{getDisplayName(stopArea)} lägen</legend>
          <StopPointsConfigurator
            stopArea={stopArea}
            stopPointGids={stopPointGids}
            onChange={setStopPointGids}
          ></StopPointsConfigurator>
          {stopPointGids !== 'all' && (
            <label className={styles['show-platforms-in-header']}>
              <input
                type="checkbox"
                checked={showPlatformsInHeader}
                onChange={onShowPlatformsInHeaderChange}
              />
              Visa lägen i headern
            </label>
          )}
        </fieldset>
      )}

      <fieldset>
        <legend>Moduler</legend>
        <label className={styles.option}>
          <input
            type="checkbox"
            value="departures"
            checked={modules.includes('departures')}
            onChange={onModuleChange}
          />
          Avgångar
        </label>
        <label className={styles.option}>
          <input
            type="checkbox"
            value="trafficSituations"
            checked={modules.includes('trafficSituations')}
            onChange={onModuleChange}
          />
          Störningar
        </label>
      </fieldset>

      <fieldset>
        <legend>Avgångsvisning</legend>
        <label className={styles['long-option']}>
          <input
            type="radio"
            checked={departureTimeFormat === 'countdown'}
            name={departureTimeFormatName}
            value="countdown"
            onChange={onDepartureTimeFormatChange}
          />
          Nedräkning i minuter för närmsta timmen för nästa och därefter
          avgångar, uppdelat på sidor vid behov
        </label>
        <label className={styles['long-option']}>
          <input
            type="radio"
            checked={departureTimeFormat === 'time'}
            name={departureTimeFormatName}
            value="time"
            onChange={onDepartureTimeFormatChange}
          />
          Visa enskilda avgångar för kommande dygn sorterade på avgångstid med
          tidsvisning enligt klocka, begränsat till max en sida avgångar
        </label>
      </fieldset>

      {departureTimeFormat !== 'time' && (
        <fieldset>
          <legend>Sortering av avgångar</legend>
          <label className={styles.option}>
            <input
              type="radio"
              checked={departureSorting === 'alphabetical'}
              name={departureSortingName}
              value="alphabetical"
              onChange={onDepartureSortingChange}
            />
            Alfabetiskt
          </label>
          <label className={styles.option}>
            <input
              type="radio"
              checked={departureSorting === 'time'}
              name={departureSortingName}
              value="time"
              onChange={onDepartureSortingChange}
            />
            Avgångstid
          </label>
        </fieldset>
      )}
    </div>
  );
}
