import { useEffect } from 'react';

export default function useLayoutScale(boardConfigurations?: number): void {
  useEffect(() => {
    function shownInIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }

    const isShownInIframe = shownInIframe();
    const isGecko = navigator.userAgent.includes('Gecko/');

    function getSmallestSize(): number {
      // Gecko/Firefox adjusts windows sizes with zoom level while also adjusting window.devicePixelRatio, making it impossible to correctly set a initial scale for high pixel density screens while still allowing the user to zoom
      if ((isGecko && !isShownInIframe) || !boardConfigurations) {
        return 720;
      }

      const widthScaleValue = 0.8; // Scale down UI for width as most information is shown horizontally
      const isPortrait = window.innerHeight >= window.innerWidth;
      const widthMultiplier = isPortrait
        ? widthScaleValue
        : widthScaleValue / boardConfigurations;
      const heightMultiplier = isPortrait ? 1 / boardConfigurations : 1;
      return isShownInIframe
        ? Math.min(
            window.innerWidth * widthMultiplier,
            window.innerHeight * heightMultiplier
          )
        : Math.min(
            window.outerWidth * widthMultiplier,
            window.outerHeight * heightMultiplier
          );
    }

    function updateRemScale(): void {
      const smallestSize = getSmallestSize();
      const scale = smallestSize / 1080;
      const fontSize = `${scale * 16}px`;
      document.documentElement.style.fontSize = fontSize;
      document.documentElement.style.setProperty('--root-font-size', fontSize);
    }

    updateRemScale();

    const resizeObserver = new ResizeObserver(updateRemScale);
    resizeObserver.observe(document.documentElement);

    () => {
      resizeObserver.disconnect();
    };
  }, [boardConfigurations]);
}
