import { Theme } from '../models/theme';
import styles from './ImageIcon.module.scss';
import spinnerImage from '@assets/images/spinner.svg';
import spinnerLightThemeImage from '@assets/images/light-theme/spinner.svg';
import brandingImage from '@assets/images/vasttrafik.svg';
import brandingLightThemeImage from '@assets/images/light-theme/vasttrafik.svg';
import trafficSituationSlightImage from '@assets/images/traffic-situation-slight.svg';
import trafficSituationNormalImage from '@assets/images/traffic-situation-normal.svg';
import trafficSituationSevereImage from '@assets/images/traffic-situation-severe.svg';
import wheelcharAccessibleImage from '@assets/images/wheelchair-accessible.svg';
import wheelcharAccessibleLightThemeImage from '@assets/images/light-theme/wheelchair-accessible.svg';
import campaignImage from '@assets/images/campaign.svg';
import campaignLightThemeImage from '@assets/images/light-theme/campaign.svg';
import transportModeTramImage from '@assets/images/transport-mode-tram.svg';
import transportModeTramLightThemeImage from '@assets/images/light-theme/transport-mode-tram.svg';
import transportModeBusImage from '@assets/images/transport-mode-bus.svg';
import transportModeBusLightThemeImage from '@assets/images/light-theme/transport-mode-bus.svg';
import transportModeFerryImage from '@assets/images/transport-mode-ferry.svg';
import transportModeFerryLightThemeImage from '@assets/images/light-theme/transport-mode-ferry.svg';
import transportModeTrainImage from '@assets/images/transport-mode-train.svg';
import transportModeTrainLightThemeImage from '@assets/images/light-theme/transport-mode-train.svg';
import { forwardRef, LegacyRef, useContext } from 'react';
import { ThemeContext } from './Boards';

export type Icon =
  | 'branding'
  | 'spinner'
  | 'traffic-situation-slight'
  | 'traffic-situation-normal'
  | 'traffic-situation-severe'
  | 'wheelchair-accessible'
  | 'campaign'
  | 'transport-mode-tram'
  | 'transport-mode-bus'
  | 'transport-mode-ferry'
  | 'transport-mode-train';

interface ImageIconProps {
  type: Icon;
  alt: string;
  className?: string;
}

const ImageIcon = forwardRef(
  (
    { type, alt, className }: ImageIconProps,
    ref: LegacyRef<HTMLImageElement>
  ) => {
    const theme = useContext(ThemeContext);

    function getImageSource(theme: Theme): string {
      switch (type) {
        case 'spinner':
          return theme === 'light' ? spinnerLightThemeImage : spinnerImage;
        case 'branding':
          return theme === 'light' ? brandingLightThemeImage : brandingImage;
        case 'traffic-situation-slight':
          return trafficSituationSlightImage;
        case 'traffic-situation-normal':
          return trafficSituationNormalImage;
        case 'traffic-situation-severe':
          return trafficSituationSevereImage;
        case 'wheelchair-accessible':
          return theme === 'light'
            ? wheelcharAccessibleLightThemeImage
            : wheelcharAccessibleImage;
        case 'campaign':
          return theme === 'light' ? campaignLightThemeImage : campaignImage;
        case 'transport-mode-tram':
          return theme === 'light'
            ? transportModeTramLightThemeImage
            : transportModeTramImage;
        case 'transport-mode-bus':
          return theme === 'light'
            ? transportModeBusLightThemeImage
            : transportModeBusImage;
        case 'transport-mode-ferry':
          return theme === 'light'
            ? transportModeFerryLightThemeImage
            : transportModeFerryImage;
        case 'transport-mode-train':
          return theme === 'light'
            ? transportModeTrainLightThemeImage
            : transportModeTrainImage;
      }
    }

    return (
      <img
        ref={ref}
        className={`${styles.icon}${
          className !== undefined ? ' ' + className : ''
        }`}
        src={getImageSource(theme)}
        title={alt}
      />
    );
  }
);

ImageIcon.displayName = 'ImageIcon';

export default ImageIcon;
