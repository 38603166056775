import styles from './DeparturesMessage.module.scss';

interface DeparturesMessageProps {
  title: string;
  text: string;
}

export default function DeparturesMessage({
  title,
  text,
}: DeparturesMessageProps) {
  return (
    <div className={styles['message-container']}>
      <div className={styles.message}>
        <h2 className={styles.message__title}>{title}</h2>
        <p className={styles.message__text}>{text}</p>
      </div>
    </div>
  );
}
