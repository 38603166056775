import { RefObject, useEffect, useState } from 'react';

interface UseElementSizeOptions {
  /** If the ref object is currently being rendered. Default true. */
  renderRef?: boolean;

  /** The box sizing to use for the element size. Default is 'border-box'. */
  boxSizing?: 'content-box' | 'border-box';

  /** If margins should be included in size. Default false. */
  includeMargin?: boolean;
}

interface ElementSize {
  width: number | undefined;
  height: number | undefined;
}

export default function useElementSize(
  ref: RefObject<HTMLElement>,
  { renderRef, boxSizing, includeMargin }: UseElementSizeOptions = {}
) {
  const [size, setSize] = useState<ElementSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (renderRef) {
      onResize();
    }

    function getSize(ref: RefObject<HTMLElement>): ElementSize {
      if (!ref.current) {
        return { width: undefined, height: undefined };
      }
      const bounds = ref.current.getBoundingClientRect();

      let width = bounds.width;
      let height = bounds.height;

      if (boxSizing === 'content-box') {
        const style = window.getComputedStyle(ref.current);
        width -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
        height -=
          parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
      }

      if (includeMargin) {
        const style = window.getComputedStyle(ref.current);
        width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
        height += parseFloat(style.marginTop) + parseFloat(style.marginBottom);
      }

      return {
        width,
        height,
      };
    }

    function onResize() {
      setSize(getSize(ref));
    }

    const resizeObserver = new ResizeObserver(onResize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [boxSizing, includeMargin, ref, renderRef]);

  return size;
}
