import {
  BoardConfiguration,
  IncompleteStopBoardConfiguration,
} from '@shared/models/boardConfiguration';

export interface BoardConfigurator {
  id: number;
  boardConfiguration?: IncompleteStopBoardConfiguration | BoardConfiguration;
  isValid: boolean;
}

export type BoardConfigurationReducerAction =
  | BoardConfigurationAddedAction
  | BoardConfigurationAddedOrUpdatedAction
  | BoardConfigurationDeletedAction;

export interface BoardConfigurationAddedAction {
  type: 'added';
  boardConfiguration?: IncompleteStopBoardConfiguration;
}

export interface BoardConfigurationAddedOrUpdatedAction {
  type: 'addedOrUpdated';
  id: number;
  boardConfiguration: IncompleteStopBoardConfiguration | BoardConfiguration;
}

export interface BoardConfigurationDeletedAction {
  type: 'deleted';
  id: number;
}

let nextConfiguratorId = 0;
function getNextConfiguratorId(): number {
  return nextConfiguratorId++;
}

function isBoardConfiguration(
  boardConfiguration:
    | undefined
    | IncompleteStopBoardConfiguration
    | BoardConfiguration
): boardConfiguration is BoardConfiguration {
  return boardConfiguration !== undefined && 'type' in boardConfiguration;
}

function getIsValid(
  boardConfiguration: IncompleteStopBoardConfiguration | BoardConfiguration
): boolean {
  return (
    isBoardConfiguration(boardConfiguration) &&
    boardConfiguration.modules.length > 0
  );
}

function setConfigurationIds(
  boardConfigurators: BoardConfigurator[]
): BoardConfigurator[] {
  let nextConfigurationId = 1;
  return boardConfigurators.map((boardConfigurator) => {
    if (
      boardConfigurator.isValid &&
      isBoardConfiguration(boardConfigurator.boardConfiguration)
    ) {
      return {
        ...boardConfigurator,
        boardConfiguration: {
          ...boardConfigurator.boardConfiguration,
          id: nextConfigurationId++,
        },
      };
    }

    return {
      ...boardConfigurator,
      boardConfiguration:
        boardConfigurator.boardConfiguration instanceof Object
          ? {
              ...boardConfigurator.boardConfiguration,
              id: -1,
            }
          : undefined,
    };
  });
}

export function createEmptyDepartureBoardConfiguration(): IncompleteStopBoardConfiguration {
  return {
    id: -1,
    type: 'stop',
    modules: ['departures', 'trafficSituations'],
  };
}

export function createBoardConfigurators(
  boardConfigurations: BoardConfiguration[]
): BoardConfigurator[] {
  const boardConfigurators = boardConfigurations.map((boardConfiguration) => {
    return {
      id: getNextConfiguratorId(),
      isValid: getIsValid(boardConfiguration),
      boardConfiguration,
    };
  });

  const boardConfiguratorsToUse =
    boardConfigurators.length == 0
      ? [
          {
            id: getNextConfiguratorId(),
            isValid: false,
            boardConfiguration: createEmptyDepartureBoardConfiguration(),
          },
        ]
      : boardConfigurators;

  return setConfigurationIds(boardConfiguratorsToUse);
}

export default function boardConfigurationsReducer(
  boardConfigurators: BoardConfigurator[],
  action: BoardConfigurationReducerAction
): BoardConfigurator[] {
  switch (action.type) {
    case 'added': {
      const newBoardConfigurators = [
        ...boardConfigurators,
        {
          id: getNextConfiguratorId(),
          boardConfiguration: action.boardConfiguration,
          isValid: false,
        },
      ];
      return setConfigurationIds(newBoardConfigurators);
    }

    case 'addedOrUpdated': {
      const existingBoardConfigurator = boardConfigurators.find(
        (boardConfigurator) => boardConfigurator.id === action.id
      );
      if (existingBoardConfigurator === undefined) {
        const newBoardConfigurators = [
          ...boardConfigurators,
          {
            id: getNextConfiguratorId(),
            boardConfiguration: action.boardConfiguration,
            isValid: getIsValid(action.boardConfiguration),
          },
        ];
        return setConfigurationIds(newBoardConfigurators);
      }

      const updatedConfigurator = {
        ...existingBoardConfigurator,
        boardConfiguration: action.boardConfiguration,
        isValid: getIsValid(action.boardConfiguration),
      };

      const hasNotChanged =
        JSON.stringify(updatedConfigurator) ===
        JSON.stringify(existingBoardConfigurator);

      if (hasNotChanged) {
        return boardConfigurators;
      }

      const newBoardConfigurators = boardConfigurators.map(
        (boardConfigurator) =>
          boardConfigurator.id === action.id
            ? updatedConfigurator
            : boardConfigurator
      );
      return setConfigurationIds(newBoardConfigurators);
    }

    case 'deleted': {
      const newBoardConfigurators = boardConfigurators.filter(
        (boardConfigurator) => boardConfigurator.id !== action.id
      );
      return setConfigurationIds(newBoardConfigurators);
    }
  }
}
