/**
 * Sets the maximum font size for a text to fit in a container.
 *
 * @param container The container to fit text for.
 * @param singleLine Controls if line breaks between words are allowed or not.
 */
export function setMaxFontSize(
  container: HTMLElement,
  singleLine: boolean,
  reservedSpace?: number
): void {
  const computedStyle = window.getComputedStyle(container);
  const fontFamily = computedStyle.getPropertyValue('font-family');
  const fontWeight = computedStyle.getPropertyValue('font-weight');
  const containerPaddingLeft = parseFloat(
    computedStyle.getPropertyValue('padding-left')
  );
  const containerPaddingRight = parseFloat(
    computedStyle.getPropertyValue('padding-right')
  );
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }
  const defaultFontSize = parseFloat(
    computedStyle.getPropertyValue('font-size')
  );
  ctx.font = `${fontWeight} ${defaultFontSize}px ${fontFamily}`;
  if (!container.textContent) {
    return;
  }
  const texts = singleLine
    ? [container.textContent]
    : container.textContent.split(' ');
  let widest = texts
    .map((textToMeasure) => {
      return {
        text: textToMeasure,
        width: ctx.measureText(textToMeasure).width,
      };
    })
    .sort((first, second) => second.width - first.width)[0];
  if (!widest) {
    return;
  }
  const containerWidth =
    container.getBoundingClientRect().width -
    (containerPaddingLeft + containerPaddingRight);

  const fullTextWidth = ctx.measureText(container.textContent).width;
  if (fullTextWidth <= containerWidth) {
    widest = {
      text: container.textContent,
      width: fullTextWidth,
    };
  }

  const availableWidth = containerWidth - (reservedSpace ?? 0);

  // Only use 99% of theoretically available space to take rounding errors into consideration
  const maxFontSize = (availableWidth / widest.width) * defaultFontSize * 0.99;
  let fontSize = Math.min(defaultFontSize, maxFontSize);

  // In Firefox for small sizes of fonts where there are large discrete jumps between sizes we might still end up with a font-size that is too large
  let fontFits = false;
  do {
    ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
    const widthInFontSize = ctx.measureText(widest.text).width;
    fontFits = widthInFontSize + 1 <= availableWidth;
    if (!fontFits) {
      fontSize -= 1;
    }
  } while (!fontFits && fontSize > 1);

  container.style.setProperty('font-size', `${fontSize}px`);
}
